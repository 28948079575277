import React from 'react'
import Button from 'components/Button/Button'

import { CareersbannersecStyles } from './Careersbannersec.styles'

function Careersbannersec(props) {
  const {
    careersBannerData: { careersBannerProps },
  } = props

  return (
    <CareersbannersecStyles>
      <div className="container">
        <div className="banner-content">
          <h1>{careersBannerProps.bannerHeading}</h1>
          <p>{careersBannerProps.bannerDetails}</p>
          {careersBannerProps.buttonName ? (
            <Button
              buttonName={careersBannerProps.buttonName}
              buttonLink={careersBannerProps.buttonLink}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </CareersbannersecStyles>
  )
}

export default Careersbannersec
