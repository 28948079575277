import styled from 'styled-components'
import { themeColor, xl, lg, md } from 'config/variables'

export const CareersnumbersecStyles = styled.div`
margin-bottom:127px;
${xl(`
    margin-bottom: 100px; 
`)} 
${lg(`
    margin-bottom: 60px; 
`)} 
${md(`
    margin-bottom: 40px; 
`)} 
.container {
  max-width:1220px;
}
.numberListRow {
  display:flex;
  margin-top:40px;
  box-shadow: 0 5px 10px 0 #a1afdd17;
  border: solid 1px #e8e9f3;
  background-color: #ffffff80;
  ${lg(`
      overflow:auto;
  `)} 
  .numberListItem {
    flex: 0 1 20%;
    border-right:1px solid #e9eaf2;
    padding:30px 30px 26px 30px;
    ${xl(`
        padding:20px; 
    `)} 
    ${lg(`
        padding:20px; 
        flex:0 0 200px;
    `)} 
    ${md(`
        padding:15px; 
    `)} 
    &:last-child {
      border-right:0px;
    }
    .numberListIcon {
      display:flex;
      margin-bottom:15px;
    }
    .numberListContent {
      h3 {
        font-size: 36px;
        font-weight: 600;
        line-height:1.2;
        margin:0px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        line-height:1.3;
        color: ${themeColor};
        margin:0px;
      }
    }
  }
}
`
