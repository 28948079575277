import {
  black,
  lg,
  md,
  primaryColor,
  sh,
  sm,
  themeColor,
  xl,
} from 'config/variables'
import styled, { keyframes } from 'styled-components'
import { images } from 'config/images'

const Gradient = keyframes`
  0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
`
export const CareerserenvironmentsecStyles = styled.div`
padding-bottom:20px; 
 ${md(`
    padding-bottom:10px; 
`)}
.container {
    max-width:1146px;
}
.heading-wrapper {
    max-width:100%;
}
.serviceSec {
    margin-top:60px;
    margin-bottom:80px;
    ${xl(`
       margin-top:50px;
       margin-bottom:60px;
    `)}
    ${lg(`
        margin-top:40px;
        margin-bottom:30px;
    `)}
    .serviceSecRow {
        display:flex;
        margin:0 -27px;
         ${xl(`
            margin:0 -15px;
        `)}
         ${lg(`
            margin:0 -15px;
            overflow:auto;
            padding:20px 0 30px;
        `)}
        .serviceSecItem {
            padding:0 27px;
             ${xl(`
                padding:0 15px;
            `)}
             ${lg(`
                padding:0 15px;
                flex:0 0 320px;
            `)}
            .service-single {
                height:100%;
                flex-direction:column;
                border:none;
                box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);
                border-radius:3px;
                padding:34px 26px 19px 34px;
                 ${xl(`
                    padding:20px;
                `)}
                 ${lg(`
                    padding:20px;
                `)}
                .service-img-blk {
                    height:90px;
                    width:90px;
                    margin:0 0 25px;
                }
                .service-content-blk {
                    flex:1 1 auto;
                    h4 {
                        font-size: 22px;
                        font-weight: 600;
                        line-height:28px;
                        color: ${primaryColor};
                        margin:0 0 15px;
                    }
                }
            }
        }
    } 
} 
.teamCardSec {
        .team-card-single{
            max-width: 1068px;
            width: 100%;
            position: relative;
            margin: 0 auto;
            margin-bottom: 60px;
            padding-top: 22px;
            overflow: hidden;
            ${sh(`
                max-width: 940px;
            `)}
            ${xl(`
                margin-bottom: 50px;
            `)}
            ${lg(`
                margin-bottom: 40px;
            `)}
            
            ${md(`
                margin-bottom: 30px;
            `)}
            
            &.very-light-pink-five{
                .team-card-bg {
                    background: #ffe7de;
                }
                .team-card-wrap {
                    background-image: linear-gradient(247deg, rgba(255, 244, 240, 0.7) 100%, rgba(250, 232, 226, 0.7) 0%);
                    background-size: 100% 100%;
                    transition: all 0.3s ease;
                }
            } 
            &.light-sky-blue{
                .team-card-bg {
                    background: #cceffd;
                }
                .team-card-wrap {
                    background-image: linear-gradient(247deg, rgba(226, 247, 255, 0.7) 100%, rgba(225, 246, 255, 0.7) 0%);
                    background-size: 100% 100%;
                    transition: all 0.3s ease;
                }
            } 
            .team-card-iiner {
                display: block;
                .team-card-bg {
                    max-width: calc(100% - 22px);
                    height: calc(100% - 22px);
                    position: absolute;
                    width: 100%;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    ${sm(`
                    max-width: calc(100% - 12px);
                    height: calc(100% - 12px);
                    `)}
                }
                &:hover {
                    .team-card-wrap {
                        animation: ${Gradient} 5s ease infinite;
                        box-shadow: 5px -5px 30px -10px rgba(0,0,0,0.1);
                        transform: translate(3px,-3px);
                        ${md(`
                        animation: initial;
                        box-shadow: inherit;
                        transform: inherit;
                        `)}
                        .content-block {
                            h3 {
                                color: ${themeColor};
                            }
                            .line{
                                &:before{
                                    width: 100%;
                                }
                            }
                            .btn-read{
                                color: ${black};
                            }
                        }
                    }
                }
                .team-card-wrap {
                    max-width: calc(100% - 22px);
                    display: flex;
                    ${lg(`	
                        flex-wrap: wrap;
                    `)}
                    ${sm(`
                        max-width: calc(100% - 12px);
                    `)}
                    .content-block {
                        flex: 1 1 auto;
                        padding: 62px 20px 20px 68px;
                        ${sh(`
                            padding: 50px 20px 20px 50px;
                        `)}        
                        ${xl(`
                            width: 100%;
                            padding: 50px 20px 40px 68px;
                        `)}
                        ${lg(`
                            width: 100%;
                            padding: 30px 20px 30px 50px;
                        `)}
                        ${md(`
                            padding: 15px 15px 20px 30px;
                        `)}
                        h3 {
                            font-size: 29px;
                            line-height: 37px;
                            margin-bottom: 19px;
                            color: ${primaryColor};
                            transition: all 0.3s ease;
                            ${sh(`
                                font-size: 26px;
                                line-height: 34px;
                            `)}
                            ${md(`
                                font-size: 25px;
                                line-height: 32px;
                            `)}
                            ${sm(`
                                font-size: 22px;
                                line-height: 30px;
                                margin-bottom: 10px;
                            `)}
                        }
                        p {
                        font-size: 22px; 
                        line-height: 29px;
                        margin-bottom: 30px;
                        color: ${primaryColor};
                        transition: all 0.5s ease;
                        ${sh(`
                            font-size: 20px;
                            line-height: 27px;
                            margin-bottom: 50px;
                        `)}
                        ${md(`
                            font-size: 20px;
                            line-height: 27px;
                            margin-bottom: 40px;
                        `)}
                        ${sm(`
                            font-size: 18px;
                            line-height: 28px;
                            margin-bottom: 30px;
                        `)}
                        }
                        ul {
                            margin:0px;
                            padding:0px;
                            li {
                                font-size:22px;
                                line-height:1.3;
                                padding-left:34px;
                                color: ${primaryColor};
                                margin-bottom:15px;
                                list-style:none;
                                background:url(${
                                  images.listTickIcon
                                }) no-repeat left top 3px;
                                ${sh(`
                                    font-size: 20px;
                                    line-height: 27px;
                                    margin-bottom: 10px;
                                `)}
                                ${md(`
                                    font-size: 20px;
                                    line-height: 27px;
                                    margin-bottom: 10px;
                                `)}
                                ${sm(`
                                    font-size: 18px;
                                    line-height: 28px;
                                    margin-bottom: 10px;
                                `)}
                            }
                        }
                    }
                    .img-block {
                    flex: 0 0 578px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    position: relative;
                    ${xl(`
                        display:none;
                    `)}
                    ${lg(`
                        display:none;
                    `)}
                    .gatsby-image-wrapper{
                        width: 100%;
                        height:100%;
                    }
                    img {
                        display: block;
                        margin: 0 auto;
                        vertical-align: middle;
                        ${md(`
                            margin: 0 auto;
                        `)}
                        }
                    }
                }
            }
            &.leftImage {
                .team-card-wrap { 
                    .content-block{
                        order:2;
                        padding: 62px 28px 20px 20px;
                        ${xl(`
                            width: 100%;
                            padding: 50px 20px 40px 68px;
                        `)}
                        ${lg(`
                            width: 100%;
                            padding: 30px 20px 30px 50px;
                        `)}
                        ${lg(`
                            width: 100%;
                            padding: 30px 20px 30px 50px;
                        `)}
                        ${md(`
                            width: 100%;
                            padding: 15px 15px 20px 30px;
                        `)}
                    }
                    .img-block {
                        order:1;
                    }
                }
            }
        }
    }
`
