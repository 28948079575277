import React, { memo } from 'react'
import SingleService from 'components/SingleService/SingleService'
import { CareersteamvaluesecStyles } from './Careersteamvaluesec.styles'
import LazyLoad from 'react-lazyload'
import { useSlider } from 'components/hooks/useSlider'

function Careersteamvaluesec(props) {
  const {
    careersTeamValueData: { careersTeamValueProps },
  } = props
  return (
    <CareersteamvaluesecStyles>
      <div className="heading-wrapper">
        <h3>{careersTeamValueProps.TeamValueHeading}</h3>
      </div>
      <div className="team-value-list-wrap">
        <div className="team-value-list">
          {careersTeamValueProps.teamValueContent.map((teamValueProp, i) => {
            return (
              <div key={i} className="team-value-wrap">
                <div className="teamValueItem">
                  <div className="teamValueThumb">
                    <LazyLoad height={50} once offset={2000}>
                      <img
                        src={teamValueProp.teamValueThumb}
                        alt={teamValueProp.teamValueAlt}
                      />
                    </LazyLoad>
                  </div>
                  <div className="teamValueContent">
                    <h5>{teamValueProp.teamValueText}</h5>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </CareersteamvaluesecStyles>
  )
}

export default Careersteamvaluesec
