import React from 'react'

import { CompanyImageSecStyles } from './CompanyImageSec.styles'
import Image from 'components/ImgOptimized'

function CompanyImageSec(props) {
  return (
    <CompanyImageSecStyles>
      <div className="img-wrapper">
        {props.imageItem.map((items, j) => {
          return (
            <div className="img-item" key={j}>
              <Image
                fluid={items}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 593,
                }}
              />
            </div>
          )
        })}
      </div>
    </CompanyImageSecStyles>
  )
}

export default CompanyImageSec
