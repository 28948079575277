import React, { memo } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout/Layout'
import styled from 'styled-components'
import { sm } from 'config/variables'
import { CareersProps } from 'components/Props/careers'
import SEO from 'components/Seo'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import Careersbannersec from 'components/Careersbannersec/Careersbannersec'
import Careerserenvironmentsec from 'components/Careerserenvironmentsec/Careerserenvironmentsec'
import Careersbenefitssec from 'components/Careersbenefitssec/Careersbenefitssec'
import Careershiringsec from 'components/Careershiringsec/Careershiringsec'
import Careersteamvaluesec from 'components/Careersteamvaluesec/Careersteamvaluesec'
import Careersnumbersec from 'components/Careersnumbersec/Careersnumbersec'
import Careertestimonialssec from 'components/Careertestimonialssec/Careertestimonialssec'
import CompanyImageSec from 'components/CompanyImageSec/CompanyImageSec'
import GreatPlaceWok from 'components/GreatPlaceWok/GreatPlaceWok'

export const CareersPage = styled.div`
  .banner {
    margin-bottom: 50px;
    ${sm(`
      margin-bottom: 40px;
    `)}
    .banner-content {
      max-width: 763px;
    }
    .banner-img {
      display: none;
    }
  }
`

const careers = memo(props => {
  const { location } = props

  let careersCard = [
    props.data.teamCardImage1.childImageSharp.fluid,
    props.data.teamCardImage2.childImageSharp.fluid,
  ]
  let item = [
    props.data.companyImageOne.childImageSharp.fluid,
    props.data.companyImageThree.childImageSharp.fluid,
    props.data.companyImageTwo.childImageSharp.fluid,
    props.data.companyImageFour.childImageSharp.fluid,
    props.data.companyImageFive.childImageSharp.fluid,
    props.data.companyImageSix.childImageSharp.fluid,
    props.data.companyImageSeven.childImageSharp.fluid,
    props.data.companyImageEight.childImageSharp.fluid,
    props.data.companyImageNine.childImageSharp.fluid,
    props.data.companyImageTen.childImageSharp.fluid,
  ]
  let GreatPlaceWokLogo = [props.data.GreatPlaceWokLogo.childImageSharp.fluid]

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout
      mainClass="careers-page"
      data={{ showGreatPlaceLogo: true }}
      location={location}
    >
      <SEO
        title="Careers"
        description=""
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/careers/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/careers/',
          },
          {
            property: 'og:image',
            content: 'https://www.simform.com/banner.png',
          },
        ]}
      />
      <CareersPage>
        <Careersbannersec careersBannerData={CareersProps} />
        <div className="lazy-load-div">
          <GreatPlaceWok
            greatPlaceWokData={CareersProps}
            logo={GreatPlaceWokLogo}
          />
          <Careerserenvironmentsec
            careersErEnvironmentData={CareersProps}
            fluid={careersCard}
          />
        </div>
        {showContent && (
          <>
            <Careersbenefitssec careersBenefitsdata={CareersProps} />
            <CompanyImageSec imageItem={item} />
            <Careershiringsec careersHiringData={CareersProps} />
            <Careersteamvaluesec careersTeamValueData={CareersProps} />
            <Careersnumbersec careersnumberData={CareersProps} />
            <Careertestimonialssec
              careertestimonialsData={CareersProps}
              {...props}
            />
          </>
        )}
      </CareersPage>
    </Layout>
  )
})

export default careers

export const pageQuery = graphql`
  query {
    teamCardImage1: file(
      relativePath: { regex: "/culture-images-new@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 578) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    teamCardImage2: file(
      relativePath: { regex: "/accelerate-career-new@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 578) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    testimonialsImage: file(
      relativePath: { regex: "/careers-testimonial-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageOne: file(
      relativePath: { regex: "/company-image-one@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageTwo: file(
      relativePath: { regex: "/company-image-two@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageThree: file(
      relativePath: { regex: "/company-image-three@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageFour: file(
      relativePath: { regex: "/company-image-four@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageFive: file(
      relativePath: { regex: "/company-image-five@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageSix: file(
      relativePath: { regex: "/company-image-six@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageSeven: file(
      relativePath: { regex: "/company-image-seven@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageEight: file(
      relativePath: { regex: "/company-image-eight@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageNine: file(
      relativePath: { regex: "/company-image-nine@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    companyImageTen: file(
      relativePath: { regex: "/company-image-ten@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    GreatPlaceWokLogo: file(
      relativePath: { regex: "/great-place-work-logo@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 280) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
