import styled from 'styled-components'
import {
  lg,
  sm,
  md,
  sh,
  primaryColor,
  white,
  themeColor,
  xl,
} from 'config/variables'
import { images } from 'config/images'

export const CareersbannersecStyles = styled.div`
padding: 197px 0 116px;
background: linear-gradient(to bottom , #ecf5ff, #fff);
margin-bottom:80px;
min-height:641px;
position: relative;
 ${xl(`
      padding: 140px 0 80px;
      margin-bottom:40px;
  `)}
 ${lg(`
      padding: 90px 0 40px;
      margin-bottom:40px;
  `)}
 ${md(`
      padding: 350px 0 0px;
      margin-bottom:40px;
  `)}
&::after {
  content:'';
  width:100%;
  height:100%;
  position: absolute;
  top:0;
  right:0px;
  z-index:1;
  background: url(${images.careersBanner}) no-repeat right top;
   ${xl(`
        background-size: 570px;
    `)}
   ${lg(`
        background-size: 400px;
    `)}
   ${md(`
        height:250px;
        background:url(${images.careersBannerLeptop}) no-repeat center center;
        background-size: 300px;
        top:70px;
    `)}
}
.container {
    max-width:1220px;
}
.banner-content {
    max-width: 570px;
    margin:0 ;
    position: relative;
    z-index:10;
    ${sh(`
        max-width: 500px;
    `)}
    ${xl(`
        max-width: 430px;
    `)}
    ${lg(`
        max-width: 420px;
    `)}
    ${md(`
        max-width: 100%;
    `)}
    .heading-red {
      background-image: linear-gradient(100deg, #3d3d3d, #ff4d63);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    p {
      font-size: 25px;
      letter-spacing: -0.15px;
      line-height: 34px;
      margin-bottom: 38px;
      color: ${primaryColor};
      ${sh(`
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 30px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      `)}
      
    }
    .button  {
        color: ${white};
        background: ${themeColor};
        border: 1px solid ${themeColor};
        &:hover {
            color: ${themeColor};
            background-color:${white};  
        }  
        &:after,
        &:before { 
            background: ${white};
        }
    }
  }
`
