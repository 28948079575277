import React from 'react'

import { CareersbenefitssecStyles } from './Careersbenefitssec.styles'
import SingleService from 'components/SingleService/SingleService'

function Careersbenefitssec(props) {
  const { careersBenefitsProps } = props.careersBenefitsdata

  return (
    <CareersbenefitssecStyles>
      <div className="container">
        <div className="heading-wrapper">
          <h2>{careersBenefitsProps.serviceHeading}</h2>
        </div>
        <div className="custom-dev-listing">
          {careersBenefitsProps.serviceContents.map((serviceContent, i) => {
            return <SingleService key={i} {...serviceContent} />
          })}
        </div>
      </div>
    </CareersbenefitssecStyles>
  )
}

export default Careersbenefitssec
