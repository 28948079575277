import React from 'react'
import LazyLoad from 'react-lazyload'

import { CareersnumbersecStyles } from './Careersnumbersec.styles'

function Careersnumbersec(props) {
  const {
    careersnumberData: { careersnumberProps },
  } = props

  return (
    <CareersnumbersecStyles>
      <div className="container">
        <div className="heading-wrapper">
          <h3>{careersnumberProps.heading}</h3>
        </div>
        <div className="numberListRow">
          {careersnumberProps.numberListContent.map((numberListProps, i) => {
            return (
              <div key={i} className="numberListItem">
                <div className="numberListIcon">
                  <LazyLoad height={50} once offset={2000}>
                    <img
                      src={numberListProps.iconUrl}
                      alt={numberListProps.iconAlt}
                    />
                  </LazyLoad>
                </div>
                <div className="numberListContent">
                  <h3>{numberListProps.Number}</h3>
                  <p>{numberListProps.numberListContent}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </CareersnumbersecStyles>
  )
}

export default Careersnumbersec
