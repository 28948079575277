import styled, { keyframes } from 'styled-components'
import {
  metaFont,
  themeColor,
  primaryColor,
  black,
  md,
  lg,
  xl,
  sm,
  xll,
  sh,
} from 'config/variables'

import { images } from 'config/images'

const Gradient = keyframes`
  0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
`
export const CareertestimonialssecStyles = styled.div`
  margin-bottom:102px;
  ${xl(`
    margin-bottom: 80px; 
  `)} 
  ${lg(`
      margin-bottom: 60px; 
  `)} 
  ${md(`
      margin-bottom: 40px; 
  `)} 
    .testimonials-slider {
    margin-top: 50px;
    padding-bottom: 160px;
    ${xl(`
        padding-bottom: 120px;
    `)} 
    ${lg(`
    margin-top: 40px;
        padding-bottom: 100px;
    `)} 
    ${md(`
    margin-top: 30px;
    padding-bottom: 80px;
    `)} 
    .slick-list{
      .slick-track{
        display: flex;
        .slick-slide {
          display: flex;
          height: auto;
          opacity: 0.5;
          padding: 0 30px;
          transition: all 0.5s ease;
          ${xl(`
            padding: 0 20px;
          `)}
          ${lg(`
            padding: 0 20px;
          `)}
          ${md(`
            padding: 0 15px;
          `)}
          ${sm(`
            padding: 0 12px;
            opacity: 1;
          `)}
          &.slick-current {
            opacity: 1;
          }
          > div{
            height: 100%;
            .testimonials-single {
              max-width: 1066px;
              margin-bottom: 0;
              height: 100%;
              position: relative;
              margin: 0 auto; 
              padding-top: 22px;
              overflow: hidden;
              ${xl(`
                max-width: 864px;
              `)}
              ${lg(`
                max-width: 608px;
              `)}
              ${md(`
                max-width: 320px;
                padding-top: 12px;
              `)}
              ${sm(`
                max-width: 95%;
                margin-left:2.5%;
              `)}
               &.duck-egg-blue {
                .testimonials-bg {
                  background: #c2f4f0;
                }
                .testimonials-wrap {
                  background-image: linear-gradient(
                    113deg,
                    rgba(222, 255, 252, 0.7),
                    rgba(207, 254, 250, 0.7)
                  );
                  background-size: 400% 400%;
                  transition: all 0.3s ease;
                }
              }
               &.light-peach {
                .testimonials-bg {
                  background: #ffe1c1;
                }
                .testimonials-wrap {
                  background-image: linear-gradient(
                    247deg,
                    rgba(255, 240, 225, 0.7),
                    rgba(255, 238, 219, 0.7)
                  );
                  background-size: 400% 400%;
                  transition: all 0.3s ease;
                }
              }
               &.light-sky-blue {
                .testimonials-bg {
                  background: #cceffd;
                }
                .testimonials-wrap {
                  background-image: linear-gradient(
                    247deg,
                    rgba(226, 247, 255, 0.7),
                    rgba(225, 246, 255, 0.7)
                  );
                  background-size: 400% 400%;
                  transition: all 0.3s ease;
                }
              }
              &.light-grey-two {
                .testimonials-bg {
                  background: #bae597;
                }
                .testimonials-wrap {
                  background-image: linear-gradient(247deg,rgba(209, 231, 191, 0.7),rgba(222, 254, 197, 0.7));
                  background-size: 400% 400%;
                  transition: all 0.3s ease;
                }
              }
               &.powder-blue {
                .testimonials-bg {
                  background: #cdd3e2;
                }
                .testimonials-wrap {
                  background-image: linear-gradient(247deg,rgba(218, 226, 240, 0.68),rgba(205, 213, 228, 0.68));
                  background-size: 400% 400%;
                  transition: all 0.5s ease;
                }
              }
               &:hover {
                  .testimonials-wrap {
                    animation: ${Gradient} 5s ease infinite;
                    box-shadow: 5px -5px 30px -10px rgba(0,0,0,0.1);
                    transform: translate(3px,-3px);
                    ${md(`
                      animation: initial;
                      box-shadow: inherit;
                      transform: inherit;
                    `)}
                    .content-block {
                      h3 {
                        color: ${themeColor};
                      }
                      .line{
                        &:before{
                          width: 100%;
                        }
                      }
                      .btn-read{
                        color: ${black};
                      }
                    }
                  }
                }
              .testimonials-bg {
                max-width: calc(100% - 22px);
                height: calc(100% - 22px);
                position: absolute;
                width: 100%;
                top: 0;
                right: 0;
                z-index: -1;
                ${md(`
                  max-width: calc(100% - 12px);
                  height: calc(100% - 12px);
                `)}
                }
                .testimonials-wrap {
                    max-width: calc(100% - 22px);
                    display:flex;
                    height: 100%;
                    ${md(`
                      max-width: calc(100% - 12px);
                    `)}
                  .content-block {
                    padding: 47px 44px 80px 72px;
                    ${xl(`
                      padding: 40px 40px 60px 40px;
                    `)}
                    ${lg(`
                      padding: 30px 30px 40px 30px;
                    `)}
                    ${md(`
                      padding: 20px 20px 30px 25px;
                    `)}
                    ${sm(`
                      padding: 20px 15px 20px 25px;
                    `)}
                    p {
                      font-size: 20px;
                      font-weight: 700; 
                      line-height: 36px;
                      letter-spacing: 0.4px;
                      color: ${primaryColor};
                      font-family: ${metaFont};
                      margin-bottom:34px;
                      span {
                        color: ${themeColor}
                      }
                      ${lg(`
                          margin-bottom:20px;
                          font-size: 18px;
                          line-height: 30px;
                      `)} 
                      ${md(`
                         font-size: 16px;
                         line-height: 25px;
                      `)} 
                    }
                    .userDetails {
                      a {
                      display:flex;
                      font-size: 20px;
                      font-weight: 400;
                      line-height: 1; 
                      flex-wrap:wrap;
                      align-items:center;
                      color: ${primaryColor};
                      position: relative;
                      transition: color 0.3s ease;
                      ${md(`
                          padding-left:25px;
                          font-size: 14px;
                          line-height: 1.3; 
                       `)} 
                       &:hover {
                         color: ${themeColor};
                       }
                      .socialIcon {
                        margin-right:15px;
                        flex:0 0 30px;
                        img {
                          max-width:100%;
                        }
                        ${md(`
                          position: absolute;
                          top:2px;
                          left:0px;
                          width:20px;
                       `)} 
                      }
                      .nameDivider {
                        margin:0 10px;
                        ${md(`
                          display:none;
                        `)} 
                      }
                      .socialUserName {
                        font-weight: 500;
                        ${md(` 
                          width:100%;
                          display:block;
                       `)} 
                      }
                      }
                      .withOutSocial {
                      display:flex;
                      font-size: 20px;
                      font-weight: 400;
                      line-height: 1; 
                      flex-wrap:wrap;
                      align-items:center;
                      color: ${primaryColor};
                      position: relative;
                      transition: color 0.3s ease;
                      ${md(`
                          font-size: 14px;
                          line-height: 1.3; 
                       `)} 
                      .nameDivider {
                        margin:0 10px;
                        ${md(`
                          display:none;
                        `)} 
                      }
                      .socialUserName {
                        font-weight: 500;
                        ${md(` 
                          width:100%;
                          display:block;
                       `)} 
                      }
                      }
                    }
                  }
                }
            }
          }
        }
      }
    }
    .slick-arrow {
      width: 100px;
      position: absolute;
      padding: 0;
      top: 50%;
      z-index: 9;
      height: 100px;
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.06),
        0 3px 5px -1px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      margin-top: -50px;
      border-radius: 50%;
      transition: all 0.3s ease;
      outline: none;
      opacity: 1;
      border: 0;
      display:flex;
      align-items:center;
      justify-content:center;
      cursor: pointer;
      ${sh(`
        width: 80px;
        height: 80px;
        margin-top: -40px;
      `)}
      ${md(`
        width: 50px;
        height: 50px;
        margin-top: -25px;
      `)}
      ${md(`
        width: 40px;
        height: 40px;
        margin-top: -20px;
      `)}
      svg {
        width: auto;
        stroke: initial;
        ${sh(`
          width: 25px;
        `)}
        ${md(`
          width: 20px;
          height: auto;
        `)}
        ${md(`
          width: 15px;
          height: auto;
        `)}
      }
      &:hover {
        opacity: 0.8;
      }
      &.slick-prev {
        left: calc((100vw - 1140px) / 2 - 100px);
        ${xll(`
          left: 20px;
        `)}
        ${xl(`
          left: 20px;
        `)}
        ${lg(`
          left: 30px;
        `)}
        ${sm(`
          left: 8px;
        `)}
      }
      &.slick-next {
        right: calc((100vw - 1140px) / 2 - 100px);
        ${xll(`
          right: 20px;
        `)}
        ${xl(`
          right: 20px;
        `)}
        ${lg(`
          right: 30px;
        `)}
        ${sm(`
          right: 8px;
        `)}
      }
    }
    .slick-dots{
      position: absolute;
      left: 0;
      right: 0;
      bottom: -60px;
      display: flex !important;
      justify-content: center;
       ${md(`
        bottom: -50px;
      `)}  
      li{
        list-style: none;
        margin: 0 8px;
        ${sm(`
          margin: 0 5px;
        `)}
        button{
          border: 0;
          cursor: pointer;
          font-size: 0;
          width: 24px;
          height: 4px;
          background: ${themeColor};
          border-radius: 2px;
          padding: 0;
          display: block-size;
          opacity: 0.2;
          transition: all 0.3s ease;      
        }
        &.slick-active{
          button{
            opacity: 1;
          }
        }
      }
    }
  }
  .contact-info-card {
    .container {
    .btn-col {
      .button  {
        padding:18px 30px 20px;
         ${md(`
            padding:18px 10px 20px;
            letter-spacing: 1px;
            font-size: 13px;
          `)} 
      }
    }
    }
  }

`
