import styled from 'styled-components'
import { lg, sm, device, primaryColor, xl, md } from 'config/variables'

export const CareersbenefitssecStyles = styled.div`
 margin-bottom: 0;
 padding-bottom:100px;
 position: relative;
 ${xl(`
    padding-bottom:80px;
`)} 
${lg(`
    padding-bottom:60px;
`)} 
${md(`
    padding-bottom:40px;
`)} 
 &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 684px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(rgba(238, 242, 255, 0), rgb(238, 242, 255));
      z-index: -1;
    }

  .heading-wrapper {
    max-width: 670px;
  }
  .custom-dev-listing {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    box-shadow: 0 5px 10px 0 rgba(161, 175, 221, 0.09);
    border-top: solid 1px #e8e9f3;
    border-left: solid 1px #e8e9f3;
    background-color: rgba(255, 255, 255, 0.5);
    ${lg(`
      flex-wrap: nowrap;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      border: 0;
      background:transparent;
    `)}
    .service-single {
      max-width: 33.33%;
      border: 1px solid rgba(194, 197, 212, 0.3);
      border-top: 0;
      border-left: 0;
      box-shadow: inherit;
      background:transparent;
      padding: 30px;
      flex-direction:column;
      background-color: rgba(255, 255, 255, 0.5);
      &:hover {
        transform: translateY(0);
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.05),
          0 9px 46px 8px rgba(0, 0, 0, 0.01),
          0 24px 38px 3px rgba(0, 0, 0, 0.05);
        position: relative;
        z-index: 1;
        ${device(`
          box-shadow: inherit;
        `)}
        &.sage-green {
          background:#e4ffd9;
          }
          &.sky-blue {
            background-color: rgb(234, 241, 255);
          }
          &.sandy-color {
           background-color: rgb(255, 244, 220);
          }
          &.pale-orange {
            background-color: rgb(255, 239, 239); 
          }
          &.carnation {
            background: #FFEAEE;
          }
          &.perrywinkle {
            background:#ece8fd;
          }
      }
      ${lg(`
          min-width: 314px;
          margin-right: 20px;
          border: 1px solid #d8d8d8;
          padding: 20px;
      `)}
      ${sm(`
        min-width: 270px;
      `)}
      .service-img-blk {
          margin:0px 0 22px;
        ${sm(`
          margin-bottom: 15px;
        `)}
      }
      .service-content-blk {
        h4 {
           font-size: 22px;
           font-weight: 500;
           line-height:28px;
           margin:0 0 9px;
        }
        p {
          font-size: 18px;
          line-height:25px;
          color:${primaryColor};
        }
      }
      &.sage-green {
        .service-content-blk {
          h4 {
            color:#85c26a;
          }
        }
      }
      &.sky-blue {
        .service-content-blk {
          h4 {
            color:#6cbaf7;
          }
        }
      }
      &.sandy-color {
        .service-content-blk {
          h4 {
            color:#f2ca7a;
          }
        }
      }
      &.pale-orange {
        .service-content-blk {
          h4 {
            color:#f6a25f;
          }
        }
      }
      &.carnation {
        .service-content-blk {
          h4 {
            color:#ef7c91;
          }
        }
      }
      &.perrywinkle {
        .service-content-blk {
          h4 {
            color:#8d7fd8;
          }
        }
      }
    }
  }
`
