import styled from 'styled-components'
import { lg, sm, device, primaryColor, xl, md } from 'config/variables'

export const GreatPlaceWokStyles = styled.div`
  margin-bottom: 100px;
  padding: 60px 0;
  position: relative;
  background: #ebfaffb3;
  ${lg(`
    margin-bottom: 60px; 
`)}
  ${md(`
    margin-bottom: 40px;
    padding:40px 0;
`)} 
  .sec-row {
    display: flex;
    align-items: center;
    justify-content: center;
    ${md(`
         flex-direction: column;
    `)}
    .section-thumb {
      width: 240px;
      max-width: 240px;
      flex: 0 0 240px;
      ${md(`
        margin-bottom:40px;
      `)}
    }
    .description {
      padding-left: 50px;
      max-width: 780px;
      ${lg(`
         padding-left:30px;
      `)}
      ${md(`
        padding-left:0;
      `)} 
      h2 {
        margin: 0 0 40px;
        ${md(`
          margin: 0 0 30px;
        `)}
      }
      .heading-red {
        background-image: linear-gradient(100deg, #3d3d3d, #ff4d63);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }
    }
  }
`
