import styled from 'styled-components'
import { md, sm, sh, device, lg, xl } from 'config/variables'
import { images } from 'config/images'

export const CareersteamvaluesecStyles = styled.div`
 margin-bottom:100px;
  overflow: hidden;
  ${xl(`
    margin-bottom: 80px; 
  `)} 
  ${lg(`
      margin-bottom: 60px; 
  `)} 
  ${md(`
      margin-bottom: 40px; 
  `)} 
  .heading-wrapper{
    ${md(`
      padding: 0 15px;
    `)}
  }
  .team-value-list-wrap {
    overflow: hidden;
    position: relative;
    background:url(${images.teamValueBg2x});
    padding: 110px 0 114px;
    margin-top:52px;
    background-size:cover;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    text-align:center;
     ${xl(`
       padding: 80px 0;
    `)} 
    ${lg(`
        padding: 60px 0;
        margin-top:40px;
    `)} 
    ${md(`
        padding: 40px 0; 
    `)} 
    .team-value-list {
      padding: 0 0 80px;
      margin:0 auto;
      display: inline-flex;
      ${md(`
         padding: 0 0 80px;
      `)}
      .team-value-wrap{
        position: relative;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { width: 0 !important }  
        &:nth-child(2n) {
          top: 80px;
        }
        .teamValueItem {
            box-shadow: 0 3px 5px -1px #00000019;
            background:#fff;
            margin:0 16px;
            width:240px;
            padding:30px 40px 20px; 
            text-align:center;
            .teamValueThumb {
              margin-bottom:17px;
            }
            .teamValueContent {
              h5 {
                font-size: 18px;
                font-weight: 600; 
                text-align: center; 
                line-height:1;
              }
            }
        } 
      }
    }
  }
`
