import { lg, md, sm, xl } from 'config/variables'
import styled from 'styled-components'

export const CompanyImageSecStyles = styled.div`
  width: 100%; 
  padding: 0 4px;
  margin-bottom: 100px;
  ${xl(`
    margin-bottom: 80px;
  `)} 
  ${lg(`
      margin-bottom: 60px;
  `)} 
  ${md(`
      margin-bottom: 40px;
  `)} 
  .img-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1.5px; 
    .img-item {
      flex: 0 0 20%;
      width:  20%;
      max-width:  20%;
      padding: 1.5px;
      opacity: 0.4;
      transition: all 0.3s ease-in-out; 
      ${md(`
          flex: 0 0 50%;
          width: 50%;
          max-width: 50%;
      `)} 
      ${sm(`
          flex: 0 0 100%;
          width: 100%;
          max-width: 100%;
      `)}
      .gatsby-image-wrapper {
        max-width: 100% !important;
      }
    }
  }
`
