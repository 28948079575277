import React from 'react'
import SingleService from 'components/SingleService/SingleService'

import { CareerserenvironmentsecStyles } from './Careerserenvironmentsec.styles'
import Image from 'components/ImgOptimized'

function Careerserenvironmentsec(props) {
  const {
    careersErEnvironmentData: { careersErEnvironmentProps },
  } = props
  return (
    <CareerserenvironmentsecStyles>
      <div className="container">
        <div className="heading-wrapper">
          <h2>{careersErEnvironmentProps.heading}</h2>
        </div>
        <div className="serviceSec">
          <div className="serviceSecRow">
            {careersErEnvironmentProps.serviceContents.map(
              (serviceContent, i) => {
                return (
                  <div key={i} className="serviceSecItem">
                    <SingleService {...serviceContent} />
                  </div>
                )
              }
            )}
          </div>
        </div>
        <div className="teamCardSec">
          {careersErEnvironmentProps.teamCardDetails.map((teamCardProps, i) => {
            return (
              <div
                key={i}
                className={`team-card-single ${teamCardProps.teamCardClassName ||
                  ''}`}
              >
                <div className="team-card-iiner">
                  <div className="team-card-bg"></div>
                  <div className="team-card-wrap">
                    <div className="content-block">
                      <h3>{teamCardProps.cardHeading}</h3>
                      <p>{teamCardProps.cardInfo}</p>
                      <ul>
                        {teamCardProps.teamCardList.map(
                          (teamCardListProps, i) => {
                            return <li key={i}>{teamCardListProps.list}</li>
                          }
                        )}
                      </ul>
                    </div>
                    <div className="img-block">
                      <Image
                        fluid={props.fluid[i]}
                        alt={teamCardProps.teamCardAlt}
                        lazyload={{
                          enabled: true,
                          once: true,
                          offset: 1000,
                          heigth: 430,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </CareerserenvironmentsecStyles>
  )
}

export default Careerserenvironmentsec
