import React from 'react'

import { GreatPlaceWokStyles } from './GreatPlaceWok.styles'
import Image from 'components/ImgOptimized'
import Button from 'components/Button/Button'

function GreatPlaceWok(props) {
  const { greatPlaceWokProps } = props.greatPlaceWokData

  return (
    <GreatPlaceWokStyles>
      <div className="container">
        <div className="sec-row">
          <div className="section-thumb">
            <Image
              fluid={props.logo}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 411,
              }}
            />
          </div>
          <div className="description">
            <h2 className="heading-red">{greatPlaceWokProps.description}</h2>
            {greatPlaceWokProps.ButtonText ? (
              <Button
                targetBlank={true}
                buttonName={greatPlaceWokProps.ButtonText}
                buttonLink={greatPlaceWokProps.ButtonTextURL}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </GreatPlaceWokStyles>
  )
}

export default GreatPlaceWok
