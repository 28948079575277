import React from 'react'
import { images } from 'config/images'

export const CareersProps = {
  careersBannerProps: {
    bannerHeading: (
      <>
        Learn.
        <span className="heading-red">Grow.Play</span>{' '}
      </>
    ),
    bannerDetails: (
      <>
        Learning, growth, and work-life balance are foundation blocks of the
        work culture at Simform. This is because workforce satisfaction forms an
        integral aspect of organization values at Simform.
      </>
    ),
    BannerAlt: 'Career at Simform',
    buttonName: 'Join Simform Family',
    buttonLink: '/current-openings/',
  },
  greatPlaceWokProps: {
    description:
      'Our company culture is amazing and our Great Place to Work Certification proves it.',
  },
  careersErEnvironmentProps: {
    heading: (
      <>
        Lean engineering <span className="highlight-head">environment</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Latest engineering best practices',
        serviceParagraph: (
          <>
            At Simform we have a passion for good engineering, and it reflects
            on the client we work with and the team we pick.
          </>
        ),
        serviceIcon: images.latestPracticesIcon,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Tech horizontals agnostic',
        serviceParagraph: (
          <>
            We are not focused on technology horizontals. Rather, we emphasize
            building scalable and robust software with advanced engineering.
          </>
        ),
        serviceIcon: images.horizontalsAgnostic,
        serviceAlt: 'Mobile',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Agile and flexible teams',
        serviceParagraph: (
          <>
            We build a very agile team for client's projects. So teams are lean
            enough to rapidly learn and adapt to newer technologies and
            practices.
          </>
        ),
        serviceIcon: images.agileFlexibleTeams,
        serviceAlt: 'Manual',
        serviceClass: 'beige-four',
      },
    ],
    teamCardDetails: [
      {
        cardHeading: <>Young team, thriving culture</>,
        cardInfo:
          'With our enthusiastic young team members, we have built a vibrant and open culture without bureaucratic hierarchies.',
        teamCardList: [
          { list: 'Young and ambitious team' },
          { list: 'Open and flat culture' },
        ],
        teamCardClassName: 'very-light-pink-five',
        teamCardAlt: 'Young team thriving culture',
      },
      {
        cardHeading: <> Accelerate career </>,
        cardInfo:
          'Fast-paced learning is what drives our engineering culture. So the team gets to learn fast with a learning program, learning perks, and a clear career path.',
        teamCardList: [
          { list: 'Ample learning opportunities' },
          { list: 'Well-defined career path' },
        ],
        teamCardClassName: 'light-sky-blue leftImage',
        teamCardAlt: 'Young team thriving culture',
      },
    ],
  },

  careersBenefitsProps: {
    serviceHeading: (
      <>
        <span className="highlight-head">Benefits</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Flexible timing, leaves benefits for life events',
        serviceParagraph: (
          <>
            {' '}
            Flexible timing and work hours. Wedding, Parenthood, Bereavement
            leaves. 10-12 holidays, 10 Casual leaves, 8 emergency leaves{' '}
          </>
        ),
        serviceIcon: images.flexibleTimingIcon,
        serviceAlt: 'Flexible timing',
        serviceClass: 'sage-green',
      },
      {
        id: '2',
        serviceHeading: 'Great recreational facilities',
        serviceParagraph: (
          <>
            {' '}
            Game lounge featuring pool table, table tennis, Foosball, Gaming
            consoles and more.
          </>
        ),
        serviceIcon: images.facilitiesIcon,
        serviceAlt: 'Facilities',
        serviceClass: 'sky-blue',
      },
      {
        id: '3',
        serviceHeading: 'Free Health insurance',
        serviceParagraph: (
          <>
            Free 5Lac family coverage with zero co-pay, no room rent cap,
            pre-existing disease covered from day one and the maternity cover
          </>
        ),
        serviceIcon: images.healthInsuranceIcon,
        serviceAlt: 'Manual',
        serviceClass: 'sandy-color',
      },
      {
        id: '4',
        serviceHeading: 'Free snacks and highly affordable homely lunch',
        serviceParagraph: (
          <>
            {' '}
            Free snack in the evening. Healthy and hygienic lunch service at
            ₹400/month.
          </>
        ),
        serviceIcon: images.homelyLunchIcon,
        serviceAlt: 'affordable homely lunch',
        serviceClass: 'pale-orange',
      },
      {
        id: '5',
        serviceHeading:
          'Sponsorship for certifications/events, Library service',
        serviceParagraph: (
          <>
            {' '}
            Sponsorship for certification preparation, attending some events
            etc. Online courses subscriptions and physical library.
          </>
        ),
        serviceIcon: images.libraryIcon,
        serviceAlt: 'Library service',
        serviceClass: 'carnation',
      },
      {
        id: '6',
        serviceHeading: 'Work from home',
        serviceParagraph: (
          <>
            {' '}
            25 day of work from home after completing one year into the company.
          </>
        ),
        serviceIcon: images.workFormHomeIcon,
        serviceAlt: 'Manual',
        serviceClass: 'perrywinkle',
      },
    ],
  },
  careersHiringProps: {
    Heading: 'Are you looking for a Job change?',
    Para: 'We are hiring, nah we are growing our family.. Lets work together. ',
    buttonName: 'Check Openings and Apply',
    buttonLink: '/current-openings/',
  },

  careersTeamValueProps: {
    TeamValueHeading: <>Team Values</>,
    teamValueContent: [
      {
        teamValueText: 'Ownership over authority',
        teamValueThumb: images.ownershipCard,
        teamValueAlt: 'Ownership over authority',
      },
      {
        teamValueText: 'Collaborative learning',
        teamValueThumb: images.collaborativeCard,
        teamValueAlt: 'Collaborative learning',
      },
      {
        teamValueText: 'Everyone matters',
        teamValueThumb: images.mattersCard,
        teamValueAlt: 'Everyone matters',
      },
      {
        teamValueText: 'Clear growth path',
        teamValueThumb: images.growthPathCard,
        teamValueAlt: 'Clear growth path',
      },
      {
        teamValueText: 'Equal opportunity & gender diversity',
        teamValueThumb: images.opportunityCard,
        teamValueAlt: 'Equal opportunity & gender diversity',
      },
    ],
  },
  careersnumberProps: {
    heading: 'Numbers',
    numberListContent: [
      {
        iconUrl: images.ageIcon,
        iconAlt: 'Average Age of Team Member',
        Number: '26',
        numberListContent: 'Average Age of Team Member',
      },
      {
        iconUrl: images.teamSizeIcon,
        iconAlt: 'Team Size in 5 Years',
        Number: '5x',
        numberListContent: 'Team Size in 5 Years',
      },
      {
        iconUrl: images.yearIcon,
        iconAlt: 'Years Since Inception',
        Number: '10',
        numberListContent: 'Years Since Inception',
      },
      {
        iconUrl: images.certifiedIcon,
        iconAlt: 'Certified Engineers',
        Number: '10%',
        numberListContent: 'Certified Engineers',
      },
      {
        iconUrl: images.ratingIcon,
        iconAlt: 'Average Age of Team Member',
        Number: '4.5',
        numberListContent: 'Average Glassdoor Rating',
      },
    ],
  },
  careertestimonialsProps: {
    testimonialsheading: <>Stories</>,
    testimonialsContents: [
      {
        Comments: (
          <>
            “As for my journey with Simform, after learning and growing with
            Simform for 3 years, I quit to become an entrepreneur. Even then I
            was in touch with Maitrik sir who is always there to guide me. After
            2 years of entrepreneurship, I thought about grabbing a job again.
            <br /> <br /> I thought about joining MNC in Ahmedabad, but after
            reviewing some MNCs and giving interviews, I realised that I
            wouldn’t have got better growth and culture anywhere else but
            Simform. So, I re-joined Simform again in 2016. <br /> <br /> I get
            enough opportunities to learn & work with latest design trends and
            tools and freedom in working methods.”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/ravichothani/',
        UserName: 'Ravi Chothani',
        designation: 'Team Manager',
        testimonialsClassName: 'light-grey-two',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “I started my journey at Simform in 2013 as an engineer with dreams
            in mind. Throughout the years, I took on new roles, each presenting
            me with challenges and chances to evolve. My career mirrored the
            company’s growth. Watching Simform grow from a 20-person team to a
            global organization with 1200+ members and offices worldwide has
            been truly inspiring. <br /> <br />
            What truly defines Simform is its people. The camaraderie and
            encouragement within our teams are unmatched, creating a sense of
            unity that makes each day rewarding and stimulating. <br /> <br />
            Looking back, I'm struck by our progress. We've developed a company
            that prioritizes innovation, teamwork, and excellence. I'm excited
            to see what the future holds for Simform and for me.”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/ronak-90/',
        UserName: 'Ronak Kotecha',
        designation: 'Engineering Manager',
        testimonialsClassName: 'light-peach',
        testimonialsImgAlt: 'Mobile Mockup',
      },

      {
        Comments: (
          <>
            “Fresh out of college in 2012, I desired to do some exceptional work
            in the IT industry. Simform's management has been instrumental in
            helping me achieve that goal, backing me to pursue what was needed
            to achieve it. Eventually, I got back to back on challenging
            projects with a great, supportive team and many opportunities to
            showcase my skills. This is what motivates me to work with Simform.
            <br /> <br />A most memorable experience is when we built a
            location-based survey app to gather immediate service feedback.
            Imagine leaving a restaurant and receiving a notification to rate
            your experience right then. Our initial system triggered surveys
            upon exiting with 98% accuracy. But Simform assigned a BLE expert to
            collaborate, and together, we achieved 100% accuracy! We covered so
            many edge cases after that and this resulted in a rock-solid
            solution for our client. ”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/rajanthakrar/',
        UserName: 'Rajan Thakrar',
        designation: 'Sr. Technical Project Manager',
        testimonialsClassName: 'light-sky-blue',
        testimonialsImgAlt: 'Mobile Mockup',
      },

      {
        Comments: (
          <>
            “I have had the privilege of being a part of Simform since 2010, and
            I can confidently say that my journey here has been nothing short of
            exceptional. Throughout my tenure, I have had the opportunity to
            work on diverse projects that have challenged me and allowed me to
            grow both personally and professionally. <br /> <br />
            One of Simform's standout features is its supportive work culture,
            where collaboration and teamwork are valued and encouraged. From
            senior leadership to colleagues across departments, everyone is
            approachable and willing to lend a helping hand when needed. This
            sense of camaraderie creates a very positive work environment where
            individuals can thrive while contributing meaningfully to the
            company's success. ”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        UserName: 'Mehul Pandya',
        designation: 'Engineering Manager',
        testimonialsClassName: 'duck-egg-blue',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “I was initially drawn to Simform because of its exceptional work
            culture. It is best in town– no office politics, just a lot of
            learning and growth opportunities. People here genuinely trust each
            other and are happy to see their colleagues succeed. The projects
            are challenging, and the clients are fantastic. This keeps me
            motivated and engaged.
            <br /> <br />
            Simform's flat hierarchy stands out. You can approach anyone to
            discuss ideas or concerns, which fosters transparency and openness.
            We even have technical discussion forums where we can brainstorm
            solutions to challenges. The technical expertise within the company
            is unparalleled, and there's a high level of trust placed in
            employees. This allows us to execute projects in our own way, rather
            than being constrained by outdated methods. The freedom and support
            to innovate make Simform a unique and exciting workplace.”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/tejasardeshna/',
        UserName: 'Tejas Ardeshna',
        designation: 'Engineering Manager',
        testimonialsClassName: 'powder-blue',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “I lead two critical departments at Simform: QA Testing and
            Information Security. What I really came to understand with the role
            is that it's not just about overseeing projects; it's about creating
            a culture of shared dedication, expertise, and innovation within
            teams.
            <br />
            <br />
            One experience that perfectly captures this spirit at Simform is
            when we had a major software release for a fintech client. The
            project demanded rigorous testing to ensure compliance and iron-clad
            security to protect sensitive data. Despite the complexity and
            scale, we pulled it off with teamwork, bouncing ideas off each other
            with a laser focus on detail.
            <br />
            <br />
            It wouldn’t have been possible without the experience, culture, and
            support provided by Simform. ”
          </>
        ),
        UserName: 'Sudhansu Patel',
        designation: 'Engineering Manager - QA',
        testimonialsClassName: 'light-grey-two',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “I joined Simform in 2020 as a TPM, and the COVID-19 pandemic hit
            us. I was amazed at how the organization was ready and proactive in
            facing the situation. We were ready to go fully remote even before
            the lockdown was announced. This showed how Simform’s practices are
            truly agile and how visionary the management is.
            <br />
            <br />
            Simform has bred the ideal culture– freedom to express yourself,
            equal opportunities for growth, no bossism, and teams trained to
            quickly adopt or bounce back from any given trend or situation. This
            culture makes one more passionate about work, and I try my best to
            retain it as much as possible. ”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/mihirvshah/',
        UserName: 'Mihir Shah',
        designation: 'VP - Delivery',
        testimonialsClassName: 'light-peach',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “I joined Simform in 2016, and the most important thing that
            attracted me to the organization was its culture of collaboration
            and teamwork. Starting as a UI/UX designer, Maitrik's guidance was
            invaluable. Later, I moved to the frontend department as a team
            manager, where Hiren offered constant support. Simform has helped
            both my professional and personal development.
            <br />
            <br />I have worked at 3 other organizations before, but Simform
            stands out with its management style. Anyone can connect with
            management, and they actively listen to everyone's feedback and
            suggestions. Other things I love about working here are the flexible
            timings, affordable lunch service, and the hybrid work culture. ”
          </>
        ),
        UserName: 'Sagar Khanpara',
        designation: 'Team Manager - Front-End',
        testimonialsClassName: 'light-sky-blue',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “I have had a very rewarding professional journey at Simform. I
            began my career here as a Tech Lead, but the company's commitment to
            growth opened a door for me to step into a team management role,
            which was something I wanted. Now, I get to keep learning new tech
            while leading a fantastic team, seeing them develop their skills
            alongside me.
            <br /> <br />
            What really sets Simform apart from my previous employers is its
            constant push for even employees to grow. Plus, there's way less
            office politics and much more focus on fair treatment for all. It
            can make all the difference– people are happy here, and that shows
            in the quality of work we do. ”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/ronak-pandya-77533067/',
        UserName: 'Ronak Pandya',
        designation: 'Team Manager - DevOps',
        testimonialsClassName: 'duck-egg-blue',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “The culture and work environment at Simform is amazing. In most
            places, you get stuck doing the same thing over and over, and you
            get into a comfort zone. But Simform lets you constantly explore and
            gives you a chance to implement it.
            <br />
            <br />
            You are not judged just by your current performance here but they
            also believe in employees’ potential. They invest in helping you
            reach it. There are tons of training sessions and workshops, all
            focused on making you a better techie and a well-rounded person.
            It's clear they care about our growth just as much as the company's.
            ”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/ashish-daudani-b82671a6/',
        UserName: 'Ashish Daudani',
        designation: 'Team Manager - Data Engineering',
        testimonialsClassName: 'powder-blue',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “Simform's reputation for having a people-first company culture is
            what first drew me in, and it lives up to the hype. They offer
            flexible work arrangements so we can have a healthy work-life
            balance, and the company culture is incredibly supportive and
            inclusive.
            <br /> <br />
            But what truly keeps me motivated to stay is the constant push to
            explore new ideas. There's this incredible sense of encouragement to
            think outside the box, and my colleagues are amazing– always
            supportive and willing to help each other grow, both personally and
            professionally. ”
          </>
        ),
        UserName: 'Dharmesh Rathod',
        designation: 'Sr. Software Engineer - Java',
        testimonialsClassName: 'light-grey-two',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “At Simform, a core value held is "learning and growth."
            <br />
            <br />
            I experienced this firsthand when I was involved in building a data
            pipeline for a client in the hospitality industry. The project
            involved integrating complex features with tight deadlines. But
            senior developers provided invaluable mentorship, guiding me through
            technical challenges and best practices. The entire team environment
            was super collaborative, with open communication.
            <br />
            <br />
            The successful launch of the data pipeline, exceeding client
            expectations, was a testament to Simform's collaborative culture and
            commitment to employee development. The experience solidified my
            belief that Simform is a place where I can continuously learn, grow,
            and contribute to meaningful projects. ”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/aalap-jethwa/',
        UserName: 'Aalap Jethwa',
        designation: 'Lead Engineer - Python',
        testimonialsClassName: 'light-peach',
        testimonialsImgAlt: 'Mobile Mockup',
      },
      {
        Comments: (
          <>
            “When I was looking for a job change in 2019, I had heard about
            Simform’s supportive culture and opportunities for both personal and
            professional development, which attracted me. Simform encourages
            employees to take on new challenges and provides recognition for
            their contributions, which keeps me engaged and excited about my
            work.
            <br />
            <br />
            Simform has provided various learning resources– like subscriptions
            to platforms from which we can learn new things by ourselves to gain
            the latest knowledge about the software industry, training sessions
            to enhance our professional skills, and support for certified
            courses to help us pursue professional goals. Plus, flexible timings
            are the cherry on the cake! ”
          </>
        ),
        iconUrl: images.linkedinSocialIcon,
        iconAlt: 'Linkdin',
        socialLink: 'https://www.linkedin.com/in/shalin-jasani/',
        UserName: 'Shalin Jasani',
        designation: 'Lead Engineer - React Native',
        testimonialsClassName: 'light-sky-blue',
        testimonialsImgAlt: 'Mobile Mockup',
      },
    ],

    CTACard: {
      title: (
        <>
          Your passion begins here..!! <br /> Explore numerous job opportunities
        </>
      ),
      buttonName: 'Check Openings and Apply',
      buttonLink: '/current-openings/',
    },
  },
}
