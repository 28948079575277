import React from 'react'
import { CareertestimonialssecStyles } from './Careertestimonialssec.styles'
import ContactInfoCard from 'components/ContactInfoCard/ContactInfoCard'
import loadable from '@loadable/component'
import 'slick-carousel/slick/slick.css'

const ReactSlick = loadable.lib(() => import('react-slick'))

const settings = {
  infinite: true,
  centerMode: true,
  variableWidth: true,
  slidesToShow: 1,
  speed: 800,
  dots: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  centerPadding: 0,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        variableWidth: false,
      },
    },
  ],
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <button className="slick-arrow slick-prev" onClick={onClick}>
      <svg width="32" height="25" viewBox="0 0 32 25">
        <path
          fill="#3D3D3D"
          fillRule="evenodd"
          d="M0 12a1.22 1.22 0 0 1 0-.13v-.11a1.25 1.25 0 0 1 0-.13l.05-.1.07-.12.07-.09.08-.1L11 .39a1.3 1.3 0 0 1 1.82 0 1.25 1.25 0 0 1 0 1.79l-8.43 8.56h26.32a1.3 1.3 0 0 1 0 2.59H4.29l8.55 8.55a1.25 1.25 0 0 1 0 1.79 1.3 1.3 0 0 1-1.82 0L.37 13l-.08-.09-.08-.09-.06-.11-.06-.11a1.18 1.18 0 0 1 0-.12 1.22 1.22 0 0 1 0-.12 1.16 1.16 0 0 1 0-.12 1.2 1.2 0 0 1 0-.13L0 12z"
        />
      </svg>
    </button>
  )
}

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <button className="slick-arrow slick-next" onClick={onClick}>
      <svg width="32" height="25" viewBox="0 0 32 25">
        <path
          fill="#3D3D3D"
          fillRule="evenodd"
          d="M32 12a1.22 1.22 0 0 0 0-.13v-.11a1.25 1.25 0 0 0 0-.13l-.05-.1-.07-.12-.07-.09-.08-.1L21 .39a1.3 1.3 0 0 0-1.82 0 1.25 1.25 0 0 0 0 1.79l8.43 8.56H1.29a1.3 1.3 0 0 0 0 2.59h26.42l-8.55 8.55a1.25 1.25 0 0 0 0 1.79 1.3 1.3 0 0 0 1.82 0L31.63 13l.08-.09.08-.09.06-.11.06-.11a1.18 1.18 0 0 0 0-.12 1.22 1.22 0 0 0 0-.12 1.16 1.16 0 0 0 0-.12 1.2 1.2 0 0 0 0-.13L32 12z"
        />
      </svg>
    </button>
  )
}

function Careertestimonialssec(props) {
  const {
    careertestimonialsData: { careertestimonialsProps },
  } = props
  const CTACards = careertestimonialsProps.CTACard

  return (
    <CareertestimonialssecStyles>
      <div className="container">
        <div className="heading-wrapper">
          <h2>{careertestimonialsProps.testimonialsheading}</h2>
        </div>
      </div>
      <div className="testimonials-slider">
        <ReactSlick>
          {({ default: Slider }) => (
            <Slider {...settings}>
              {careertestimonialsProps.testimonialsContents.map(
                (testimonialsContent, i) => {
                  return (
                    <div
                      className={`testimonials-single ${testimonialsContent.testimonialsClassName ||
                        ''}`}
                      key={i}
                    >
                      <div className="testimonials-bg" />
                      <div className="testimonials-wrap">
                        <div className="content-block">
                          <p>{testimonialsContent.Comments}</p>
                          <div className="userDetails">
                            {testimonialsContent.socialLink ? (
                              <a
                                href={testimonialsContent.socialLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="socialIcon">
                                  {' '}
                                  <img
                                    src={testimonialsContent.iconUrl}
                                    alt={testimonialsContent.iconAlt}
                                  />
                                </span>
                                <span className="socialUserName">
                                  {testimonialsContent.UserName}
                                </span>
                                <span className="nameDivider">|</span>
                                <span>{testimonialsContent.designation}</span>
                              </a>
                            ) : (
                              <div className="withOutSocial">
                                <span className="socialUserName">
                                  {testimonialsContent.UserName}
                                </span>
                                <span className="nameDivider">|</span>
                                <span>{testimonialsContent.designation}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
            </Slider>
          )}
        </ReactSlick>
      </div>
      <ContactInfoCard {...CTACards} />
    </CareertestimonialssecStyles>
  )
}

export default Careertestimonialssec
