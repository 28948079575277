import styled from 'styled-components'
import { images } from 'config/images'
import { white, themeColor, primaryColor, xl, lg, md } from 'config/variables'

export const CareershiringsecStyles = styled.div`
margin-bottom:100px;
${xl(`
    margin-bottom: 80px; 
`)} 
${lg(`
    margin-bottom: 60px; 
`)} 
${md(`
    margin-bottom: 40px; 
`)} 
.container {
  max-width:1129px;
}
  .careersHiringWrapper {
    padding-right:20px;
    padding-bottom:20px;
    background:url(${images.careersHiringSecBg});
    .careersHiringInner {
       box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.06), 0 3px 5px -1px rgba(0, 0, 0, 0.1);
       border: solid 1px #d4e3ea;
       background-color: #fff;
       padding:30px 42px 30px 50px;
       display:flex;
       align-items:center;
       ${lg(`
          flex-direction:column;
          padding:30px;
      `)} 
      ${md(`
              padding:15px;
            `)} 
       .careersHiringDetails {
         flex:1 1 auto;
         padding-right:42px;
         ${lg(`
          padding-right:0px;
          padding-bottom:30px;
        `)} 
         h4 {
            font-size: 34px;
            font-weight: 500;
            line-height: 1.41;
            letter-spacing: -0.37px;
            color: ${primaryColor};
            margin: 0 0 9px;
            ${xl(`
               font-size: 30px;
            `)} 
            ${lg(`
               font-size: 28px;
            `)} 
            ${md(`
              font-size: 24px;
            `)} 
         }
         p {
             font-size: 18px; 
            line-height: 1.56;
            margin:0px; 
         }
       }
       .hiringBtn {
         flex:0 0 300px;
         ${lg(`
            flex:1 1 auto;
        `)} 
         .button  {
            color: ${white};
            background: ${themeColor};
            border: 1px solid ${themeColor};
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.4px;
            text-transform:uppercase;
            padding:10px 30px 10px;
             ${md(`
              padding:10px 10px 10px;
              letter-spacing: 0;
            `)} 
            &:hover {
                color: ${themeColor};
                background-color:${white};  
            }  
            &:after,
            &:before { 
                background: ${white};
            }
        }
       }
    }
  }
`
