import React from 'react'

import { CareershiringsecStyles } from './Careershiringsec.styles'
import Button from 'components/Button/Button'

function Careershiringsec(props) {
  const {
    careersHiringData: { careersHiringProps },
  } = props

  return (
    <CareershiringsecStyles>
      <div className="container">
        <div className="careersHiringWrapper">
          <div className="careersHiringInner">
            <div className="careersHiringDetails">
              <h4>{careersHiringProps.Heading}</h4>
              <p>{careersHiringProps.Para}</p>
            </div>
            <div className="hiringBtn">
              {careersHiringProps.buttonName ? (
                <Button
                  buttonName={careersHiringProps.buttonName}
                  buttonLink={careersHiringProps.buttonLink}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </CareershiringsecStyles>
  )
}

export default Careershiringsec
